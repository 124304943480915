<template>
    <div class="create_container">
        <div class="create_title">
           <h2>Create a new project</h2>

        </div>
		<div class="gplWarning"> Project with GPL protocol cannot be used to make a profit, the price should be set to zero.</div>
        <div class="create_divider"><hr style="color: lightgray"/></div>
        <div class="create_body">
                <div class="input_domain">
                    <div class="input_line"> Project board name</div>
                    <Input v-model="ct.name" style="width: 60%" placeholder="Project board name"></Input>
                </div>

                <div class="input_domain">
                    <div class="input_line"> <p style="display: inline">Description</p> <strong style="display: inline;font-size: 14px;color:darkgray">( optional )</strong></div>
                    <Input v-model="ct.abstractInfo" type="textarea"  :rows="4" placeholder="Enter something..." />
                </div>

			<div class="input_domain">
				<div class="input_line"> <p style="display: inline">Environment</p> <strong style="display: inline;font-size: 14px;color:darkgray">( optional )</strong></div>
				<Input v-model="ct.environment" type="textarea"  :rows="2" placeholder="Enter something..." />
			</div>

			<div class="input_domain">
				<div class="input_line"> <p style="display: inline">Dependencies</p> <strong style="display: inline;font-size: 14px;color:darkgray">( optional )</strong></div>
				<Input v-model="ct.dependencies" type="textarea"  :rows="2" placeholder="Enter something..." />
			</div>

			<div class="input_domain">
				<div class="input_line"> <p style="display: inline">Languages</p> <strong style="display: inline;font-size: 14px;color:darkgray">( optional )</strong></div>
				<Input v-model="ct.languages" type="textarea"  :rows="2" placeholder="Enter something..." />
			</div>

			<div class="input_domain">
				<span style="font-size: 1.125rem;">Estimated code size: </span>
				<Input v-model="ct.codeLines" style="width: 18.75rem;">
					<span slot="append">lines</span>
				</Input>
			</div>
			<div class="input_domain">
				<p style="font-size: 1.125rem; margin-bottom: 0.25rem;">Share point gain ratio(code): </p>
				<p style="font-size: 14px;color:darkgray">Default ratio: 100(lines)=1(share point)</p>
				<Input v-model="ct.linesForGetOneShare" placeholder="How many lines exchange 1 point" style="width: 28rem;">
					<span slot="append">lines = 1 point</span>
				</Input>
			</div>
			<div class="input_domain">
				<p style="font-size: 1.125rem; margin-bottom: 0.25rem;">Share point gain ratio(invest): </p>
				<Input v-model="ct.moneyorGetOneShare" placeholder="How much" style="width: 20rem;">
					<span slot="prepend"> $ </span>
					<span slot="append">= 1 point</span>
				</Input>
			</div>

			<div class="input_domain">
				<p style="font-size: 1.125rem; margin-bottom: 0.25rem;">Price of executable code: </p>
				<Input v-model="ct.executableCodePrice" placeholder="How much" style="width: 20rem;">
					<span slot="prepend"> $ </span>
				</Input>
			</div>


			<div class="input_domain">
				<span style="font-size: 1.125rem;">Voting time for Join/Extend: </span>
				<Input v-model="ct.votingTimeForJoin" placeholder="" style="width: 20rem;">
					<span slot="append">minutes</span>
				</Input>
			</div>
			<div class="input_domain">
				<span style="font-size: 1.125rem;">Voting time for amendment: </span>
				<Input v-model="ct.votingTimeForAmendment" placeholder="" style="width: 20rem;">
					<span slot="append">days</span>
				</Input>
			</div>

			<div style="font-size: 1.025rem;font-weight: 500; padding-right: 15px;margin-top:50px;margin-bottom: 5px">
				Price of Source Code
				<p style="font-size: 14px;color:darkgray;display: inline">(Dollars per 100 lines of code)</p>:
			</div>
			<p v-if="!submit_tag" style="font-size: 14px;color:darkgray"> You can just give the Initial price. You can
				also give profit-price to adjust price in profit increasing.</p>
			<div class="table_unit"
				 style="width: 100%;height:40px; font-size:1.0125rem; padding-top:10px;margin-bottom:20px;padding-left:3%;text-align:left;float:left; background-color: #F5F5F5">

				<div style="width:40%; float:left;display:inline">
					Total Profit
				</div>
				<div style="width:40%;margin-left:3%;float:left;display:inline">
					Price
				</div>
			</div>
			<Form ref="formDynamic" :model="formDynamic" style="width: 100%;float:left">
				<FormItem
						v-for="(item, index) in formDynamic.items"
						v-if="item.status"
						:key="index"
						:prop="'items.' + index + '.value'"
				>
					<div class="table_unit" style="width:100%; float:left;">
						<div class="table_unit" style="width:40%; float:left;display:inline">
							<div style="text-align: left;padding-left: 10%" v-if="index===0">0</div>
							<Input prefix="logo-usd" v-if="!submit_tag && index>0" type="number" v-model="item.profit"
								   placeholder="Enter something..."></Input>
							<div style="text-align: left;padding-left: 10%" v-if="submit_tag && index >0">
								{{item.profit}}
							</div>
						</div>
						<div class="table_unit" style="width:40%; margin-left:3%;float:left;display:inline">
							<Input prefix="logo-usd" v-if="!submit_tag" type="number" v-model="item.price"
								   placeholder="Enter something..."></Input>
							<div style="text-align: left;padding-left: 10%" v-if="submit_tag ">{{item.price}}</div>
						</div>
						<div v-if="!submit_tag" class="table_unit"
							 style="width:10%; margin-left: 3%; float:left;display:inline">
							<Button long v-if="index>0" @click="handleRemove1(index)">Delete</Button>
						</div>
						<hr style="width:100%; float:left; margin-top:12px;height:1px; border:none; background-color:#F5F5F5">
						</hr>
					</div>
				</FormItem>
				<FormItem v-if="!submit_tag">
					<div>
						<Button type="dashed" long @click="handleAdd1" icon="md-add"
								style="width: 30%;float: left;margin-left: 35%">Add item
						</Button>
					</div>
				</FormItem>
			</Form>


			<div class="input_domain">
				<span style="font-size: 1.125rem;">Hierarchy diagram of the project: </span>
				<div>
					<Upload v-if="file===''"
							:before-upload="handleUpload"
							action="">
						<Button icon="ios-cloud-upload-outline">Select the file to upload</Button>
					</Upload>
					<div v-else>
						{{file.name}}
					</div>
				</div>
			</div>

			<div class="input_domain">
				<span style="font-size: 1.125rem;">FunctionList </span>
				<div class="table_unit" style="width: 100%;height:40px; font-size:1.0125rem; padding-top:10px;margin-bottom:20px;padding-left:3%;text-align:left;float:left; background-color: #F5F5F5">
					<div style="width:10%; float:left;display:inline">
						id
					</div>
					<div style="width:35%; float:left;display:inline">
						name
					</div>
					<div style="width:35%;margin-left:3%;float:left;display:inline">
						description <strong style="display: inline;font-size: 14px;color:darkgray">( optional )</strong>
					</div>
				</div>
			<Form  style="width: 100%;float:left">
				<FormItem
						v-for="(item,index) in functionList"
						:key="index"
						:prop="'items.' + index + '.value'"
				>
					<div class="table_unit" style="width:100%; float:left;">
						<div  class="table_unit" style="width:10%; float:left;display:inline">
							Function {{index+1}}
						</div>
						<div class="table_unit" style="width:35%; float:left;display:inline">
							<div>
								<Input v-model="item.name" ></Input>
							</div>
						</div>
						<div class="table_unit" style="width:35%; margin-left:3%;float:left;display:inline">
							<div >
								<Input  v-model="item.description"></Input>
							</div>
						</div>
						<div class="table_unit" style="width:8%; margin-left:2%;float:left;display:inline">
							<Button long v-if="index===indexs && index >0" @click="handleRemove2(index)">Delete</Button>
						</div>
						<hr style="width:100%; float:left; margin-top:12px;height:1px; border:none; background-color:#F5F5F5">
						</hr>
					</div>
				</FormItem>
				<FormItem>
					<div>
						<Button type="dashed" long @click="handleAdd" icon="md-add" style="width: 30%;float: left;margin-left: 35%">Add item</Button>
					</div>
				</FormItem>
			</Form>
			</div>
        </div>

        <div class="create_divider"><hr style="color: lightgray" /></div>
        <div class="create_button_container">
            <div style="float:left; margin-left: 10%;width: 20%">
                <Button type="primary" @click="UploadFile">Submit</Button>
            </div>
            <div style="float:right; margin-left: 10%;width: 20%">
                <Button style="margin-left: 80px">Cancel</Button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "createProject",
        data () {
            return {
				file:'',
				loadingStatus:false,
				dynamicIndex:0,
				formDynamic: {
					items: [
						{
							profit: 0,
							price: '',
							index: 1,
							status: 1,
						},
						{
							profit: '',
							price: '',
							index: 2,
							status: 2,
						},

					]
				},
                ct:{
                    abstractInfo: "",
                    codeLineLimitsForOneShare: 100,
                    codeLines: 0,
                    contributionNumber: 0,
                    creationTime: "",
                    dependencies: "",
                    environment: "",
                    executableCodePath: "",
                    executableCodePrice: 0,
                    founder: "",
                    functionList: [
                    ],
                    functionNumber: 0,
                    hierarchyDiagramPath: "",
                    hierarchyList: [
                    ],
                    id: "",
                    investorNumber: 0,
                    languages: "",
                    linesForGetOneShare: 100,
                    moneyorGetOneShare: 100,
                    name: "",
                    releaseTime: "",
                    sourceCodePrice: 0,
                    totalProfit: 0,
                    updateTime: "",
                    votingTimeForAmendment: 1,
                    votingTimeForJoin: 60,
					priceList:[]
                },
				indexs: 0,
				functionList: [
					{
						name:'',
						index:0,
						description: '',
					}

				]

            }
        },
        methods:{
			handleAdd1() {
				this.dynamicIndex++;
				this.formDynamic.items.push({
					profit: '',
					price: '',
					index: this.dynamicIndex,
					status: 1,
				});
			},
			handleRemove1(index) {
				this.formDynamic.items[index].status = 0;
			},
        	UploadFile(){
				this.submit_create(this.functionList);
			},
			getCookie(cname){
				let name = cname + "=";
				let ca = document.cookie.split(';');
				for(let i=0; i<ca.length; i++) {
					let c = ca[i].trim();
					if (c.indexOf(name) !== -1) { return c.substring(name.length,c.length); }
				}
				return "";
			},
			handleUpload (file) {
				this.file = file;
				let fd = new FormData();
				fd.append('image',(this.file))
				this.$axios.post(
						"/uploadImage",
						fd,
						{
							headers: {
								'Content-Type': 'multipart/form-data'
							}
						}
				).then((response) => {
					this.ct.hierarchyDiagramPath=response.data.data.filePath;
				}).catch((error) => {
					console.log(error)
					this.$Message.error(error.message);
				})
				return false;
			},
            submit_create(fcls) {
        		if(this.ct.name === ""){
					this.$Message.error("Project board name should not be empty")
					return
				}
        		if(this.ct.votingTimeForAmendment <= 0 || this.ct.votingTimeForJoin <=0 ){
        			this.$Message.error("Vote time should be a positive number")
					return
				}
				let fcl = fcls.map(a => a.name+"---"+a.description)
				this.ct.functionNumber = fcls.length;
        		let priceList = this.formDynamic.items.map(a=>a.profit+'---'+a.price)
				console.log("f",fcl)
				console.log("p",priceList)

				this.ct.functionList = fcl;
				this.ct.founder = this.getCookie("user_id");
				this.ct.priceList = priceList;
				var lopls = JSON.stringify(this.ct);
				this.$axios({
					headers: {
						'Content-Type': 'application/json'
					},
					method: 'post',
					url:"/project/add",
					data:lopls
					}
				).then((response) => {
					let pjid = response.data.data.items.id;
					location.href='/#/projectDetail?id=' + (pjid);
				}).catch((error) => {
					console.log(error);
				})

			},
			handleAdd () {
				this.indexs++;
				this.functionList.push({
					name:'',
					index: this.indexs,
					description:" "
				});
			},
			handleRemove2 (index) {
            	this.indexs --;
				this.functionList.pop();
			}

        }
    }
</script>

<style scoped>
    .create_container{
        width: 100%;
        height: auto;
        background-color: white;
    }

    .create_title{
        height:50px;
        float: left;
        width: 100%;
        padding: 20px 15%;
        text-align: left;
    }

    .create_body{
        width: 100%;
        padding:10px 16%;
        text-align: left;

    }

    .create_divider{
        float:left;
        color: lightgray;
        height: 1px;
        width: 80%;
        margin-left: 13%;
        margin-top: 8px;
        margin-bottom: 17px;

        clear: both;
    }

    .input_domain{
        width: 100%;
        margin-bottom: 16px;
    }
    .input_line{
        font-size: 18px;
        margin-bottom: 4px;
    }
	.smallDomain{
		width: 50%;
		font-size: 1.125rem;
		margin-bottom: 14px;
	}

    .create_button_container{
        float: left;
        padding: 10px 20%;
        height: 50px;
    }

	.gplWarning{
		height:50px;
		float: left;
		width: 100%;
		padding: 20px 15%;
		text-align: left;
		color: lightcoral;
	}

</style>
